import { createGlobalStyle } from 'styled-components';
import Head from 'next/head';
import { ErrorScreen } from '@fmi/design-system';

function Error({ statusCode }) {
  const GlobalStyle = createGlobalStyle`
    html, body, #__next {
      height: 100%;
      font-family: 'Roboto', sans-serif;
      background: #F0F0F0;
    }
  `;

  const statusCodeText = statusCode.toString();

  if (statusCodeText === '404') {
    return (
      <>
        <Head>
          <title>Bidvest Life - Not Found</title>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
        </Head>
        <GlobalStyle />
        <ErrorScreen
          headerText="404 Not Found"
          heading="Well, this is awkward…"
          subheading={['We couldn’t find this page.']}
          primaryButtonText="Return To Portal"
          primaryButtonOnClick={() =>
            window.open('https://adviser.fmi.co.za', 'Bidvest Life Adviser', null)
          }
          secondaryButtonText="Bidvest Life Homepage"
          secondaryButtonOnClick={() =>
            window.open('https://www.bidvestlife.co.za', 'Bidvest Life', null)
          }
          illustration="error"
        />
      </>
    );
  }

  if (statusCodeText === '500') {
    return (
      <>
        <Head>
          <title>Bidvest Life - Internal Server Error</title>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
        </Head>
        <GlobalStyle />
        <ErrorScreen
          headerText="500 Internal Server Error"
          heading="It’s not you, it’s us…"
          subheading={['Something’s happened on our side.']}
          primaryButtonText="Return To Portal"
          primaryButtonOnClick={() =>
            window.open('https://adviser.fmi.co.za', 'Google Chrome', null)
          }
          secondaryButtonText="Reload Page"
          secondaryButtonOnClick={() => window.location.reload(true)}
          illustration="error"
        />
      </>
    );
  }

  return (
    <p>
      {statusCode
        ? `An error ${statusCode} occurred on server`
        : 'An error occurred on client'}
    </p>
  );
}

Error.getInitialProps = ({ res, err }) => {
  // eslint-disable-next-line no-nested-ternary
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default Error;
